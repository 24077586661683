.u-config {}

.u-form-box {
	padding-bottom: 50px;
}

.u-form-box:after,
.u-config:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}

.u-config .ant-tabs-nav-list {
	height: calc(100vh - 20px);
}

.u-config .ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab-active,
.u-config .ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab-active {
	border-left: 5px solid #BF3232;
}
.u-config .ant-tabs-nav{
	width: 160px;
}

.u-config .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #BF3232;
	font-weight: bold;
}

.u-form-list {
	width: 50%;
	float: left;
}

.u-form-list .ant-row {
	width: 100%;
}

.u-form-list .u-form-tit {
	text-align: right;
	width: 100%;
	display: block;
	padding: 10px 80px 10px 0;
}
.u-form-tit{
	font-weight: bold;
	font-size: 16px;
}

.u-form-label .ant-form-item-label>label {
	padding-right: 10px;
}

.u-Address-form {
	position: relative;
	overflow: hidden;
	padding: 0 0 10px 0;
	z-index: 1;
}

.u-btn-remove {
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -21px;
	display: inline-block;
}

.u-collapse-ico {
	position: absolute;
	left: 20%;
	top: 5px;
	z-index: 2;
}

.u-form-but-bar {
	border-top: 1px solid #EFF0F2;
	padding: 15px 0;
	clear: both;
	background: #fff;
	z-index: 9;
	width: 100%;
	bottom: 5px;
}
.u-form-but-center{
	text-align: center;
}

.u-add-cof {
	cursor: pointer;
	color: #3592FB;
}

.u-form-horizontal .ant-row {
	width: 80%;
	float: none;
}

.u-form-top {
	padding-bottom: 15px;
	margin-bottom: 25px;
	margin-top: -15px;
	border-bottom: 1px solid #EFF0F2;
	text-align: right;
}

.ant-form-item {
	position: relative;
}

.u-arrows {
	border: 4px solid transparent;
	border-left: 6px solid #BF3232;
	width: 0px;
	height: 0px;
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 12px;
	cursor: pointer;
}

.u-setting{
	position: absolute;
	display: inline-table;
	margin-left: 15px;
	cursor:pointer;
}

div.ant-row.ant-form-item.form-u-setting.ant-form-item-has-success {
	width: 80%;
	float: none;
	float: left;
}

div.ant-row.ant-form-item.form-u-setting{
	width: 80%;
	float: none;
	float: left;
}

.u-arrows::before {
	content: '';
	display: inline-block;
	width: 10px;
	height: 8px;
	line-height: 10px;
	background: #BF3232;
	position: absolute;
	left: -16px;
	top: -4px;
}

.u-del-btn,
.u-add-btn {
	position: absolute;
	right: -40px;
	top: 8px;
}

.u-del-btn {
	right: -20px;
}

.u-APP h3 {
	font-weight: 100;
	font-size: 14px;
	font-weight: 700;
	padding: 10px 3px;
}

.u-APP ul {
	margin: 0;
	padding: 0;
}

.u-APP ul li {
	list-style: none;
	float: left;
	padding: 0;
	margin: 0 20px 10px 0;
}

.u-APP ul li img {
	width: 120px;
	height: 120px;
	margin-bottom: 5px;
	border: 1px solid #EFF0F2;
	padding: 2px;
}
.u-Supplier-tit{
	font-weight: 100;
	font-size: 14px;
	font-weight: 700;
	padding: 10px 3px;
}
.u-Supplier-head {
	background: #fafafa;
	padding: 10px 0;
	border: 1px solid #f0f0f0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.u-Supplier .ant-input{
	border: 1px solid #f0f0f0;
	border-width: 0 1px 1px 1px;
	border-radius: 0;
	outline: none;
}
.u-Supplier textarea:focus{
	outline: none;
}
.u-uploader-box,
.u-uploader-box .ant-upload.ant-upload-select-picture-card{
	width: 245px;
	height: 168px;
	position: relative;
}
.u-uploader-img{
	width: 100%;
	padding: 0;
}
.u-uploader-del{
	position: absolute;
	right: -40px;
	bottom: 5px;
	cursor: pointer;
}

.CompanyInfo{
	width: 1024px;
	margin: 0 auto;
}
.CompanyInfo .u-collapse-ico{
	top:45px;
	left: 55px;
}
.CompanyInfo .u-Address-form .u-Address-form .u-collapse-ico{
	top:5px;
}
.CompanyInfo .ant-row {
	width: 100%;
}
.CompanyInfo .u-Address-form {
	margin: 0 0 0 -122px;
}
.CompanyInfo .u-Address-form .u-Address-form{
	margin: 0;
}

.CompanyInfo .u-btn-remove{
	right: 100px;
}
.u-operation-box{
	padding: 5px 10px 10px 10px;
	text-align: right;
}

.u-appImg-wrap{
	float: left;
}
.u-appImg .ant-upload-picture-card-wrapper{
	float: left;
	width: 182px;
	height: 162px;
}
.u-appImg .ant-upload.ant-upload-select-picture-card{
	width: 182px;
	height: 162px;
}
.u-appImg .u-appImg-item{
	float: left;
	width: 182px;
	height: 162px;
	border: 1px solid #ccc;
	position: relative;
	margin:0 15px 15px 0;
	line-height: 160px;
	text-align: center;
}
.u-appImg .u-tip{
	border:1px solid #f00;
}
.u-appImg .u-appImg-item img{
	width: 180px;
	height: 160px;
	display: inline-block;
	float: left;
}
.u-appImg .ant-upload-list{
	display: none;
}
.u-appImg .u-appImg-shade{
	background: #fff;
	opacity: 0.5;
	position: absolute;
	left: 0;
	top: 0;
	width: 180px;
	height: 160px;
	z-index: 9;
	display: none;
}

.u-appImg .u-appImg-btn{
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	width: 180px;
	height: 160px;
	line-height: 160px;
	z-index: 10;
	opacity: 1;
	display: none;
}
.u-appImg .u-appImg-item:hover .u-appImg-shade,
.u-appImg .u-appImg-item:hover .u-appImg-btn{
	display: block;
}
.u-appImg .u-appImg-btn span{
	margin: 0 5px;
	font-size: 18px;
	font-weight: bold;
}

.u-form-top1 {
	padding-bottom: 15px;
	margin-bottom: 25px;
	margin-top: -5px;
	text-align: right;
	margin-right: 300px;
}
.u-uploader-phone{
	width: 100px;
	height: 85px;
	position: relative;
}
