.size-code{
    left:-6.5%
}

.size-code>.ant-col.ant-col-9.ant-form-item-control{
    left:-1vw
}

.size-add{
    float: left;
    position: absolute;
    left: 28vw;
}

.size-edit{
    float: left;
    position: absolute;
    left: 30.5vw;
}

.size-delete{
    float: left;
    position: absolute;
    left: 33.5vw;
}

.ant-row.ant-form-item.size-name{
    margin-top: 15%;
}
