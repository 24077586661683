.ant-form-item.remark>.ant-row.ant-form-item-row{
    width: 100%;
}
.ant-form-item.remark>.ant-row.ant-form-item-row>.ant-col.ant-col-3.ant-form-item-label{
    left: -1.5%;
}
.ant-form-item.remark>.ant-row.ant-form-item-row>.ant-col.ant-col-20.ant-form-item-control{
    left: -1.5%;
}
.crm-price-add{
    float: left;
    position: relative;
    z-index: 99999;
}
.ant-form-item.crm-brand-price>.ant-row.ant-form-item-row>.ant-col.ant-col-12.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content>.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search{
    float: left;
}
.ant-form-item.crm-brand-price>.ant-row.ant-form-item-row{
    width:28%;
    right:-3%;
}
.ant-form-item.customer-attachmentId>.ant-row.ant-form-item-row>.ant-col.ant-col-15.ant-form-item-control{
    left:-1.2vw
}

.u-form-custom>.ant-row.ant-form-item-row{
    width: 50%;
    float: left;
}
