.price-tabA-table{
    padding: 20px 20px 10px 20px;
    width: 66%;
}
.price-tabB-table{
    padding: 20px 20px 10px 20px;
    width: 50%;
}
.price-tabC-table{
    padding: 20px 20px 10px 20px;
    width: 80%;
}