.u-swiper-wrap {
  position: relative;
  width: 840px;
  margin: 0 auto;
}

.u-swiper {
  padding-left: 20px;
  width: 800px;
  margin: 6px auto 0 auto;
  overflow: hidden;
}

.u-swiper:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  overflow: hidden;
}

.u-swiper .ant-card {
  float: left;
  margin: 0 20px 0 0;
}

.arrow_l,
.arrow_r {
  position: absolute;
  top: 50%;
  margin-top: -40px;
  height: 60px;
  width: 30px;
  background: none;
  border: none;
  background: none;
  transition: all 0.3s ease-in 0s;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}

.disabled {
  opacity: 0.5;
}

.arrow_l {
  left: 0;
}

.arrow_r {
  right: 0;
}



.u-box {
  position: relative;
}

.u-box-head {
  padding: 10px 0 15px 0;
}

.u-box-head-border {
  border-bottom: 1px solid #EFF0F2;
  margin-bottom: 15px;
}

.u-box-head .u-box-title {
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 0;
}

.u-box-title .anticon-caret-down {
  font-size: 12px;
}

.u-box::after,
.u-box-head::after,
.u-box-con::after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.u-mt10 {
  padding-top: 10px;
}

.u-fl {
  float: left;
}

.u-fr {
  float: right;
}

.u-sl {
  width: 58%;
  float: left;
}

.u-sr {
  width: 38%;
  float: left;
  margin-left: 3px;
}

.u-box-toolbar {
  float: right;
}

.u-box-toolbar .u-button-a,
.u-box-toolbar .u-button-b {
  padding: 0 15px 0 30px;
}

.u-icon-list {
  display: inline-block;
  margin-right: 30px;
}

.u-icon-list .anticon {
  width: 33px;
  height: 33px;
  margin-right: 10px;
  line-height: 33px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.5;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-dropdown-trigger>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-button>.anticon.anticon-down {
  font-size: 12px;
  float: right;
  margin: 6px 0 0 5px;
}

.u-icon-list span:hover .anticon {
  opacity: 1;
}

.u-icon-list a {
  color: #333;
}

/* 设置按钮 */
.u-button-a {
  border-radius: 16.5px;
  background: #BF3232;
  border-color: #BF3232;
}

.u-button-b {
  border-radius: 16.5px;
  margin-left: 20px;
}


.u-box-line {
  height: 10px;
  margin: 10px -24px 10px -24px;
  background: #f4f4f4;
  clear: both;
}

td .ant-tag {
  padding: 0 10px;
  line-height: normal;
  border-radius: 8px;
}

td>span {
  color: #3592FB;
}


/*详情*/
.detail-box {
  padding: 0;
}

.u-detail-drawer .ant-drawer-body {
  overflow-x: hidden;
}

.detail-box .u-box-toolbar {
  padding: 0;
}

.u-detail-drawer .ant-drawer-close {
  top: 9px;
  right: -15px;
  text-indent: -9999999px;
}

.u-drawer-close {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 20px;
}

.u-drawer .ant-drawer-body {
  padding-top: 0;
}

.detail-item {
  margin: 50px auto;
  padding: 50px 50px 30px 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 30px 0px rgba(211, 211, 211, 0.5);
  position: relative;
}

.detail-item:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.bg999 {
  background: #999;
}

.detail-item ul {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.detail-item li {
  float: left;
  width: 50%;
  list-style: none;
}

.detail-item li p {
  margin: 0;
  padding: 0;
}

.detail-item li p.p-mt {
  margin-top: 5px;
}

.detail-item li .f-b {
  font-weight: bold;
  color: #333;
  text-align: right;
}

.detail-item li .f-g {
  color: #50AE63;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 5px;
}

.detail-item li .f-s {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
}

.detail-item .detail-divider {
  padding: 20px 0 0 0;
}

.detail-item .detail-divider p {
  padding: 0 100px 0 0;
}

.detail-item .input-item {
  text-align: right;
}

.detail-item .detail-divider .input-item p {
  padding: 0;
}

.detail-item .input-item label {
  display: inline-block;
  width: 120px;
  margin-right: 50px;
}

.detail-item .input-item span {
  width: 200px;
  display: inline-block;
}

.u-state {
  width: 103px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  opacity: 0.6;
}

/* 编辑 */
.u-edit {
  padding-bottom: 100px;
}

.u-edit .u-box-head {
  border-bottom: 1px solid #E5E5E5;
  padding-left: 10px;
  padding-bottom: 10px;
}

.u-form {
  padding: 20px 20px 10px 20px;
}

.u-form .ant-row {
  width: 50%;
  float: left;
}
.u-form .u-form-custom100 .ant-row {
  width: 100%;
  float: left;
}

.u-form .u-form-custom {
  width: 100%;
  float: none;
}
.u-form .u-form-custom100 {
  width: 100%;
  float: none;
}
.u-form .u-form-custom>.ant-row.ant-form-item-row {
  width: 100%;
  float: none;
}
.u-form .u-form-custom100>.ant-row.ant-form-item-row {
  width: 100%;
  float: none;
}

.u-form .ant-row.u-splitLine {
  clear: both;
  width: 100%;
  padding: 0;
  text-align: center;
}

.u-splitLine .anticon {
  border-radius: 100%;
  border: 1px solid #E4E4E4;
  width: 27px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  transform: rotate(90deg);
  font-size: 12px;
  overflow: hidden;
  color: #999;
}

.u-splitLine-active .anticon {
  transform: rotate(-90deg);
}

.u-form .ant-form-item-label>label {
  padding-right: 20px;
}

.u-arrows-r {
  position: absolute;
  left: -22px;
  top: 10px;
  color: #BF3232;
  cursor: pointer;
}

.u-icon-edit {
  position: absolute;
  right: -22px;
  top: 3px;
  color: #999;
  cursor: pointer;
}

.u-form-table {
  margin: 20px 0;
}

.u-table-form {
  padding: 0;
}

.u-form-table .ant-table-tbody>tr .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.u-row-form::after,
.u-table-form:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.u-row-form {
  background: #fff;
}

.u-table-form .ant-row {
  width: 100%;
  float: none;
}

.u-table-form .ant-table-thead>tr>th,
.u-table-form .ant-table-tbody>tr>td,
.u-table-form .ant-table tfoot>tr>th,
.u-table-form .ant-table tfoot>tr>td {
  padding: 8px 16px;
}

.u-table-form .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.u-row-item {
  margin-top: 20px;
  float: left;
  width: 50%;
}

.ant-form-item-label>label.u-line-f,
.u-line-f {
  font-weight: bold;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border: 1px solid #999;
  border-width: 1px 0;
  text-align: right;
  padding: 0 0 5px 0;
}

.u-line-f div {
  border: 1px solid #999;
  padding: 0 10px 0 30px;
  border-width: 0 0 1px 0;
}

.u-row-item .u-form-text {
  text-align: right;
  line-height: 32px;
}

.u-row-item .ant-form-item-label>label::after {
  display: none;
}

.u-row-item .ant-form-item-label {
  overflow: visible;
}

.u-row-item .ant-form-item {
  margin-bottom: 15px;
}

.u-row-l .ant-form-item {
  margin-bottom: 10px;
}


.u-Selectfile {
  background: #EAEAEA;
  width: 75px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 2px;
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.u-form-buttonA,
.u-form-buttonB {
  width: 150px;
  height: 33px;
  line-height: 32px;
  padding: 0;
  background: #C8C8C8;
  border-radius: 17px;
  text-align: center;
  color: #fff;
}

.u-form-buttonB {
  background: #BF3232;
  border: none;
}

.u-form-buttonB:hover {
  border: 1px solid #BF3232;
  background: #fff;
  color: #BF3232;
}

.u-form-buttonC {
  width: 162px;
  height: 33px;
  line-height: 32px;
  padding: 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #C5C5C5;
  background: #fff;
  color: #333;
  position: relative;
    margin: 0 15px;
}

.u-form-buttonC::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 32px;
  overflow: hidden;
  position: absolute;
  right: 33px;
  top: 0;
  background: #C5C5C5;
}

.u-btnA {
  height: 33px;
  padding: 0 35px;
  line-height: 32px;
  background: #BF3232;
  border: none;
  border-radius: 18px;
  text-align: center;
  color: #fff;
  display: inline-block;
}

.u-btnA:hover {
  background: #BF3232;
  border: none;
}

.u-form-buttonC .anticon-caret-down {
  float: right;
  margin: 9px 10px 0 0;
}

.processList li {
  list-style: none;
}

.processList div {
  display: inline-block;
  width: 40%;
  margin-right: 20px;
  padding-bottom: 5px;
}

.invoice .ant-table-thead>tr>th,
.invoice .ant-table-tbody>tr>td,
.invoice .ant-table tfoot>tr>th,
.invoice .ant-table tfoot>tr>td {
  padding: 8px 16px;
  border-right: none;
}

.recordImage {
  /* min-width: 820px; */
}

.recordImage .ant-card-body {
  padding: 14px 24px;
}

.u-ipt-disabled.ant-input-disabled {
  background: none;
  color: #777;
}

.u-editlist {
  width: 260px;
}

.ant-popover-inner-content {
  padding: 0px;
}

.u-editlist .ant-list-items {
  overflow-y: auto;
  padding: 0;
  height: 245px;
}

.u-editlist .ant-list-item-meta {
  padding-right: 50px;
  padding-left: 5px;
}

.u-editlist li {
  position: relative;
  cursor: pointer;
  padding: 5px 15px;
}

.u-editlist li:hover {
  background: #f0f7fb;
}

.u-editlist li .anticon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.u-bar-actions {
  background: #fafafa;
  border-top: 1px solid #f0f0f0;
  padding: 10px 20px;
}

.u-add-actions {
  cursor: pointer;
  color: #3592FB;
}

/*tableA绿色表头*/
.u-tableA .ant-table-thead>tr>th {
  background: #51ae63;
  font-weight: 100;
  color: #fff;
  border-bottom: none;
}

.u-tableA .ant-table-thead>tr>th,
.u-tableA .ant-table-tbody>tr>td,
.u-tableA .ant-table tfoot>tr>th,
.u-tableA .ant-table tfoot>tr>td {
  border-right: none;
}

/* tabsA 改写ant tabs */
.u-tabsA .ant-tabs {
  margin: 15px 0 0 0;
}

.u-tabsA .ant-tabs-nav {
  /* border-top:1px solid #f3f3f3; */
}

.u-tabsA .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.u-tabsA .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 0 15px;
}

.u-tabsA .ant-tabs-tabpane {
  padding: 0 15px 0 15px;
}

.u-tabsA .ant-tabs {
  overflow: visible;
}

.u-tabsA .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.u-tabsA .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow: visible;
  height: 40px;
}

.u-tabsA .ant-tabs-tab {
  padding: 0 30px;
  height: 42px;
  line-height: 42px;
  margin-top: -2px;
}

.u-tabsA .ant-tabs-tab-active {
  background: #fff;
  border: 1px solid #eff0f2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 2px solid #BF3232;
}

.u-tabsA .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #BF3232;
  font-weight: bold;
}

.u-tabsA .ant-tabs-content-holder {
  padding: 10px 0;
  background: #fff;
}

.u-tabsA .ant-tabs-top>.ant-tabs-nav {
  margin: 0;
}

.u-tabsA .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.u-tabsA .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {}

.u-tabsA .ant-tabs-ink-bar {
  background: #fff;
}

/* collapseA 改写antcollapse */
.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0 0 10px 0;
}

.u-collapseA .ant-collapse-ghost {
  position: relative;
  min-height: 50px;
}

.u-collapseA .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  background: #F0F0F0;
  width: 33px;
  height: 33px;
  line-height: 38px;
  overflow: hidden;
  border: none;
  border-radius: 100%;
  color: #A4A4A4;
  left: 50%;
  top: 40px;
  margin-left: -16px;
}

.u-collapseA .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.u-collapseA .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content {
  padding-bottom: 30px;
}

.u-collapseA .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(-90deg);
}


/* 模态框 */

.ant-modal-footer .ant-btn {
  border-radius: 5px;
  background: #f5f5f5;
}

.ant-modal-footer .ant-btn-primary {
  background: #BF3232;
  border-color: #BF3232;
  border-radius: 5px;
}

.ant-modal-body {
  padding: 15px 24px;
}

.u-modalForm .ant-form-item {
  margin-bottom: 10px;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 28px;
}

.ant-input-sm {
  padding: 2px 7px;
}

/*  */

.u-PI {
  position: absolute;
  right: -22px;
  top: 3px;
  display: inline-block;
  width: 14px;
  height: 14px;
}

.u-PI .u-icon-edit {
  right: 0;
}

.u-modalForm .ant-form-item {
  margin-bottom: 5px;
}

.u-modalForm .ant-row {
  width: 100%;
  float: none;
}

.ant-btn>span {
  font-size: 14px;
  font-weight: bold;
}


.u-ellips-box {
  line-height: 28px;
  display: block;
}

.u-ellips-box>.anticon {
  color: #2C2C2C;
  font-weight: bold;
  font-size: 30px;
  width: 33px;
  height: 28px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 7px 0px rgba(124, 101, 101, 0.25);
  border-radius: 2px;
  text-align: center;
  line-height: 28px;
  margin-left: 5px;
  float: right;
  cursor: pointer;
  position: relative;
}

.u-box-fixed {
  background: #fff;
}

.u-form-button-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0px 5px 30px 0px rgba(211, 211, 211, 0.5);
  text-align: center;
  background: #fff;
  padding: 20px 0 10px 0;
  z-index: 999999;
}

.u-form-button-box:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.u-box-fixed .u-box-head {
  padding: 10px 20px 15px 30px;
  position: sticky;
  margin-left: -10px;
  top: 0;
  z-index: 9999;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 -24px;
}

tr.ant-table-expanded-row>td,
tr.ant-table-expanded-row:hover>td {
  background: #f6f6f6;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #BF3232;
}


.u-button-line {
  width: 1px;
  height: 32px;
  display: inline-block;
  background: #fff;
  margin: 0 0 0 20px;
  vertical-align: middle;
}

.u-icon-arrows {
  width: 14px;
  height: 8px;
  display: block;
  position: relative;
  margin-left: 15px;
}

.u-icon-arrows:before {
  content: '';
  width: 9px;
  height: 2px;
  display: block;
  border-radius: 2px;
  position: absolute;
  background-color: #393a3d;
  transition: all 333ms cubic-bezier(0, 0, .1, 1);
  left: 0;
  transform: rotate(45deg);
}

.u-icon-arrows:after {
  content: '';
  width: 9px;
  height: 2px;
  display: block;
  border-radius: 2px;
  position: absolute;
  background-color: #393a3d;
  transition: all 333ms cubic-bezier(0, 0, .1, 1);
  right: 0;
  transform: rotate(-45deg);
}

.u-button-a .u-icon-arrows:after,
.u-button-a .u-icon-arrows:before {
  top: 3px;
  background-color: #fff;
}

.u-button-b .u-icon-arrows:after,
.u-button-b .u-icon-arrows:before {
  top: 3px;
  background-color: #000;
}

.u-con-l {
  float: left;
  display: inline-block;
}

.u-con-r {
  float: left;
  padding-left: 15px;
}

td p {
  margin: 0;
}

.ant-list-item-meta-title {
  font-weight: 100;
}

.u-more-link {
  padding: 10px 0 0 0;
  text-align: center;
}


/* 按钮,带箭头下拉按钮 S*/
.u-dropdown-btn{
}
.u-dropdown-btn .ant-btn {
  background: #BF3232;
  border-color: #BF3232;
  color: #fff;
}
.u-dropdown-btn:hover .ant-btn{
  opacity: 0.5;
}

.u-dropdown-btn>.ant-btn:first-child:not(:last-child),
.u-dropdown-btn>span:first-child:not(:last-child)>.ant-btn {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding:  0 16px 0 20px;
  border-right: 1px solid #fff;
}

.u-dropdown-btn:hover .ant-btn:last-child:not(:first-child),
.u-dropdown-btn>.ant-btn:last-child:not(:first-child),
.u-dropdown-btn>span:last-child:not(:first-child)>.ant-btn {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0;
  padding:  0 12px 0 10px;
}

.u-dropdown-btn .ant-btn-icon-only{
  width: auto;
  padding: 0;
}

.u-arrows-wrap{
  text-align: center;
}
.u-arrows-down {
  width: 14px;
  height: 8px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.u-arrows-down:before {
  content: '';
  width: 9px;
  height: 2px;
  display: block;
  border-radius: 2px;
  position: absolute;
  background-color: #FFF;
  transition: all 333ms cubic-bezier(0, 0, .1, 1);
  left: 0;
  transform: rotate(45deg);
}

.u-arrows-down:after {
  content: '';
  width: 9px;
  height: 2px;
  display: block;
  border-radius: 2px;
  position: absolute;
  background-color: #FFF;
  transition: all 333ms cubic-bezier(0, 0, .1, 1);
  right: 0;
  transform: rotate(-45deg);
}

.u-dropdown-btnA .ant-btn {
  background: #fff;
  border-color: #d9d9d9;
  color: #333;
}

.expandRowStyl{
  background-color:lightgray
}

.u-dropdown-btnA .u-arrows-down:after,
.u-dropdown-btnA .u-arrows-down:before{
  background-color: #333;
}

.u-dropdown-btnA>.ant-btn:first-child:not(:last-child),
.u-dropdown-btnA>span:first-child:not(:last-child)>.ant-btn {
  border-right:none;
  padding:  0 10px 0 20px;
}
.u-dropdown-btnA:hover .ant-btn:last-child:not(:first-child),
.u-dropdown-btnA>.ant-btn:last-child:not(:first-child),
.u-dropdown-btnA>span:last-child:not(:first-child)>.ant-btn {
  border-left:none;
  padding:  0 15px 0 0;
}

.u-dropdown-btnA:hover .ant-btn{
  background: #fff;
  border-color: #BF3232;
  color: #BF3232;
}
.u-dropdown-btnA:hover .u-arrows-down:after,
.u-dropdown-btnA:hover .u-arrows-down:before{
  background-color: #BF3232;
}
/* 按钮,带箭头下拉按钮 E*/

.u-pag-fixed{
  background: #fff;
  border-top: 1px solid #EFF0F2;
  width: 100%;
  padding: 8px 0 5px 10px;
  position: fixed;
  bottom: 0;
}

.u-form-lr:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.ant-form-item-label > label{
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: normal;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 5px;
  height: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-tabs-tab-btn {
  outline: none;
  white-space: normal;
  text-align: left;
}

.ant-form-item .ant-input-group .ant-select, .ant-form-item .ant-input-group .ant-cascader-picker {
  width: 100%;
}

.form-text-center {
  display: flex;
  align-items:center;
}

.u-button-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0px 5px 30px 0px rgba(211, 211, 211, 0.5);
  text-align: center;
  background: #fff;
  padding: 20px 0 10px 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
}

.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-left: 20px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.u-add-list-item {
  border-bottom: 1px dashed #ccc;
  margin-top: 10px;
}

.u-btn-add {
  height: 33px;
  padding: 0 35px;
  line-height: 32px;
  background: #BF3232;
  color: #fff;
  border: none;
  border-radius: 18px;
  text-align: center;
  color: #fff;
  display: inline-block;
}
.u-btn-add:hover{
  background: #ff4d4f;
  opacity: 0.5;

}

.ant-select-tree-indent,
.ant-select-tree-indent-unit{
  position: relative;
}
.ant-select-tree-indent:before{
  position: absolute;
  top: 3px;
  left: 12px;
  width: 1px;
  height: 30px;
  border-right: 1px solid #d9d9d9;
  content: "";
}
.ant-select-tree-indent>.ant-select-tree-indent-unit:before{
  position: absolute;
  top: -30px;
  left: 12px;
  width: 1px;
  height: 31px;
  border-right: 1px solid #d9d9d9;
  content: "";
}
.ant-select-tree-switcher{
  position: relative;
}
.ant-select-tree-indent + .ant-select-tree-switcher::before{
  position: absolute;
  top: 9px;
  left:-11px;
  width: 20px;
  height: 1px;
  border-top: 1px solid #d9d9d9;
  content: "";
}

.form-message {
  background-color: #ffecec;
  border-color: #ffdde2;
  color: #b94a48;
  position: relative;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 2px;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.ant-input-wrapper.ant-input-group .ant-select.ant-select-single.ant-select-show-arrow{
  width: 55px;
}

.ant-pagination .ant-table-pagination .ant-table-pagination-left{
  position: fixed;
  bottom: 0;
}

@media print {
  .docPrint{
    margin: auto;
    padding: 50px 50px 30px 50px;
    background: rgba(255, 255, 255, 1);
  }
}

.formText{
  border: 0px;
}
.formInput{
  border: 1px solid;
}
.qiyeming{
  float: left;
}
.renming{
  float: right;
}
.ant-row.ant-form-item.item-logo{
  border-bottom: 1px dashed #eee;
  width: 100%;
}