/* clothes */
.left{
	float: left;
}
.u-divider-horizontal {
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: 14px 0;
	border-top: 1px solid rgba(0,0,0,.06)
}

.u-btn-toolbar{
	padding: 10px 10px 10px 0;
	margin-top: 14px;
}
.u-btn-toolbar .ant-btn{
	margin-right: 20px;
}
.u-border-top{
	border-top: 1px solid  rgba(0,0,0,.06);
}
.u-Pagination{
	padding-bottom: 10px;
}
.u-Pagination:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}
.u-Pagination .ant-pagination{
	float: right;
}
.u-table-photo{
	position: relative;
	padding: 0 0 10px 0;
	height: 240px;
}
.u-table{
	margin-right: 240px;
}
.u-photo{
	position: absolute;
	top: 0;
	right: 0;
	width: 240px;
	height: 223px;
	overflow: hidden;
	background: #fafafa;
}
.u-photo-left{
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	height: 223px;
	overflow: hidden;
	background: #fafafa;
}
.u-photo img{
	width: 100%;
	height: 100%;
}
.u-photo-left img{
	width: 100%;
	height: 100%;
}
.u-table-photo td{
	cursor: pointer;
}

.u-form-photo-container::after{
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}
.u-form-photo{
	width: 180px;
	height: 220px;
	padding: 10px;
	border: 1px solid rgba(0,0,0,.06);
	float: left;
}
.u-form-photo img{
	width: 100%;
	height: 100%;
}
.u-marin-left{
	margin-left: 200px;
}
.u-form-item .ant-row{
	float: left;
	width: 50%;
	margin-bottom: 5px;
}
.u-margin-tb{
	margin: 10px 0;
}
.u-tab-con::after{
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}
.u-tab-con{
	padding: 10px 0 50px 0;
}
.u-tab-con .ant-row{
	width: 50%;
	float: left;
}
.ant-input-number{
	border-radius: 4px;
}
.ant-input-number-input{
	height: 26px;
	line-height: 26px;
}
.u-tabs{
	margin-top: 10px;
}
.u-tabs .ant-tabs-tab{
	padding: 10px 30px;
}

.clickRowStyl{
	background-color:#e6f7ff;
}

.u-table-order .ant-table-cell .ant-row{
	margin: 0;
}

.u-table-order .ant-table-tbody > tr:last-child td{
	border-bottom: 1px solid #E4E4E4;
}
.u-table-order .ant-table-cell .ant-input{
	border: none;
	height: 28px;
	line-height: 28px;
	border-radius: 0;
	text-align: center;
}

.ant-tag.u-tag{
	height: 23px;
	width: 30px;
	display: inline-block;
	line-height: 23px;
	float: left;
	margin: 0 8px 0 0;
	padding: 0;
}
.cName {
	float: right;
}
.u-table-order .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
	border: none;
}
.u-goods-table-photo{
	position: relative;
}
.u-goods-table-photo .ant-table{
	margin-right: 202px;
}
.u-form-photo-r{
	position: absolute;
	right: 0px;
	top: 0;
	width: 200px;
	height: 323px;
	border: 1px solid #E0E0E0;
}
.u-form-photo-l{
	position: absolute;
	left: 0px;
	top: 40px;
	width: 200px;
	height: 323px;
	border: 1px solid #E0E0E0;
}
.u-form-photo-r img{
	width: 100%;
	height: 100%;
}

.u-goods-tableForm{
	margin-right:360px;
}
.u-goods-tableForm-r{
	position: absolute;
	right: 0px;
	top: 0;
	width: 350px;
	height: 455px;
	padding: 10px 10px 10px 20px;
	border: 1px solid #E0E0E0;
}
.utf .ant-table-tbody > tr td{
	padding: 0;
}
.u-m .ant-modal-body{
	padding-top: 15px;
	padding-bottom: 20px;
}

.u-btn-bottom{
	width: 100%;
	position: absolute;
	bottom: 0;
}

.editTable .ant-table-tbody > tr > td,
.editTable .ant-table tfoot > tr > td{
	padding: 0;
	height: 28px;
}

.editTable .ant-form-item{
	height: 28px;
	border-radius: 0;
}
.editTable .ant-input,
.editTable .ant-form-item input{
	outline: none;
	border-radius: 0;
	border: none;
	text-align: center;
}

.editTable .ant-form-item-control-input{
	min-height: 28px;
	padding: 0;
	text-align: center;
}
.u-tdColor {
	line-height:28px;
	padding-left: 5px;
}
.u-tdColor1 {
	line-height:28px;
	text-align: center;
}

.u-text-center{
	text-align: center;
}

.u-table-child .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table{
	margin: -6px -12px -6px 35px;
}
.u-table-child .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table th,
.u-table-child .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table td{
	padding: 3px 12px;
}
.u-table-child .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table th{
	background: #fafafa51;
}