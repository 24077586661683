.sketch-picker{
    width:100% !important;
}
.color-form-rgb1{
    width: 16.66%;
    float: left;
    height: 150px;
}
.color-form-rgb-edit{
    width: 200px;
    float: left;
    height: 150px;
}
.color-form-rgb1:focus{
    width: 16.66%;
    float: left;
    height: 150px;
    border-color: red;
    border-width: 3px;
}
.addColor{
    width: 150px;
    float: left;
    height: 150px;
    left: 25px;
}
.color-form-rgb2{
    width: 100%;
    float: left;
    height: 150px;
}