.u-basic-page .u-icon-list {
	margin: 0;
}

.u-basic-page .u-icon-list .anticon {
	font-weight: bold;
	font-size: 26px;
}

.u-basic-page .u-btn-list .anticon {
	vertical-align: middle;
	margin: 0 10px;
}


.u-PhotosList {
	overflow: hidden;
	margin: 0;
	padding: 0 0 0 20px;
}

.u-PhotosList li {
	list-style: none;
	float: left;
	margin: 0 20px 20px 0;
	border: 1px solid #EFF0F2;
	padding: 10px;
}

.u-PhotosList .u-imgArea {
	position: relative;
	width: 286px;
	height: 286px;
	overflow: hidden;
}

.u-PhotosList .u-txtArea {
	/*padding: 5px 10px;*/
}

.u-PhotosList li img,
.u-PhotosList li video{
	width: 286px;
	height: 286px;
	display: inline-block;
	background: #C5C5C5;
}

.u-PhotosList .u-imgArea .u-imgID {
	position: absolute;
	right: 10px;
	bottom: 5px;
}

.u-PhotosList li p {
	margin: 5px 0 0 0;
}

.u-box-con {
	height: 100%;
}

.u-Tree-l {
	width: 15vw;
	float: left;
}

.u-Table-r {
	margin-left: 200px;
	padding-left: 15px;
	overflow: hidden;
	border-left: 1px solid #EFF0F2;
	min-height: calc(100vh - 80px);
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background: #be3232d9;
	color: #fff;
}

.u-RightClickMenu {
	position: absolute;
	z-index: 99999;
	border: 1px solid #EFF0F2;
	background: #fff;
	display: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
}

.u-RightClickMenu ul {
	margin: 0;
	padding: 10px 0 5px 0;
}

.u-RightClickMenu ul li {
	list-style: none;
	clear: both;
	padding: 0 15px 5px 15px;
	cursor: pointer;
}

.u-form-wrap {}

.u-form-wrap:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}

.u-form-l {
	float: left;
	width: 50%;
}

.goodsmodal_form_left {
	float: left;
	width: 32%;
	margin-left: 16%;
	position: relative;
	z-index: 1;
}

.goodsmodal_form_right {
	width: 81.5%;
}

.brand-l {
	float: left;
	width: 50%;
	margin-left: -1.5%;
}

.brand-r {
	float: left;
	width: 50%;
}

.payment-from {
	left:-1.5%;
}

.customer-payment-from {
	left:-1.5%;
}

.ant-row.ant-form-item.customer-payment-from>.ant-col.ant-col-3.ant-form-item-label>label {
	font-size: 17px;
}

.u-form-r {
	float: left;
	position: absolute;
	height: 50%;
	width: 55%;
	right: 0;
}
.u-form-img-l {
	float: right;
	width: 90%;
}

.u-TabCon .u-form-r .ant-row {
	width: auto;
}
.payment-TabCon .u-form-r .ant-row {
	width: auto;
}

.u-PreviewImg {
	width: 142px;
	height: 122px;
	margin-bottom: 10px;
	/*background: url(/images/imgBg.png) no-repeat center center #EFF0F2;*/
	border: 1px solid #de555545;
}

.u-PreviewImg img {
	width: 140px;
	height: 120px;
	display: inline-block;
}

.u-imgUpload .ant-upload-list-picture-card-container,
.u-imgUpload .ant-upload.ant-upload-select-picture-card,
.u-imgUpload .ant-upload-list-picture-card .ant-upload-list-item {
	width: 80px;
	height: 60px;
	padding: 0;
}
.u-imgUpload .ant-upload-picture-card-wrapper{
	width: 80px;
	float: left;
}
.u-imgUpload .ant-upload-list {
	display: none;
}

.u-uploadImg{
	float: left;
	overflow-x: auto;
	max-width: 258px;
	display: flex;
	height: 80px;
	margin-bottom:5px;
}
.u-mr10 {
	margin-right: 4px;
}
.u-uploadImg-item{
	float: left;
	width: 82px;
	height: 60px;
	line-height: 58PX;
	border:1px solid #d9d9d9;
	margin-right: 4px;
	position: relative;
	text-align: center;
}
.u-uploadImg-item img{
	width: 80px;
	height: 58px;
	float: left
}
.u-uploadImg-btn-shade{
	background: #fff;
	opacity: 0.6;
	text-align: center;
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 80px;
	height: 60px;
	line-height: 60px;
	z-index: 9;
}
.u-uploadImg-btn{
	text-align: center;
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 80px;
	height: 60px;
	line-height: 60px;
	z-index: 10;
}
.u-uploadImg-btn .u-uploadImg-del{
	margin-left: 10px;
}
.u-uploadImg-item:hover .u-uploadImg-btn,
.u-uploadImg-item:hover .u-uploadImg-btn-shade{
	display: block;
}

.u-TabCon {
	padding: 10px 0 0 0;
}
.payment-TabCon {
	padding: 10px 0 0 0;
	margin-left: -6.8%;
}

.u-ThreCon {
	padding: 10px 0 0 0;
}

.u-TabCon:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}
.payment-TabCon:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}

.u-ThreCon:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	overflow: hidden;
}

.u-TabCon .ant-row {
	float: left;
	width: 50%;
}
.payment-TabCon .ant-row {
	float: left;
	width: 50%;
}

.u-ThreCon .ant-row {
	float: left;
	width: 33%;
}

.u-TabCon .u-form-l .ant-row {
	width: 100%;
}
.payment-TabCon .u-form-l .ant-row {
	width: 100%;
}

.u-ThreCon .u-form-l .ant-row {
	width: 100%;
}

.u-fixed-padding {
	padding-bottom: 60px;
}

.u-form-space .ant-form-item {
	margin-bottom: 15px;
}

.u-PartsList .ant-form-item {
	margin: 0;
	padding: 0;
}

.u-PartsList .ant-col-18 {
	display: block;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
	width: 100%;
}

.u-Table-border .ant-table table {
	border-top: 1px solid #E5E5E5;
}

.u-Table-border .ant-table-thead>tr>th {
	background: #fafafa;
	border-width: 1px 1px 1px 0;
	padding: 12px 16px;
}

.u-td-pic {
	width: 60px;
	height: 60px;
}

.u-TabCon-l {
	float: left;
	width: 180px;
	padding-right: 15px;
}

.u-TabCon-r {
	margin-left: 180px;
	border-left: 1px solid #EFF0F2;
	padding: 0 0 0 10px;
}

.u-list-device {
	margin: 0 0 10px 0;
	padding: 0;
}

.u-list-device li {
	list-style: none;
	line-height: 28px;
	border-bottom: 1px dashed #d9d9d9;
	width: 100%;
	padding-right: 22px;
	padding-left: 3px;
	position: relative;
	cursor: pointer;
}

.u-list-device .u-list-ico {
	position: absolute;
	height: 15px;
	top:5px;
	right: 0;
	cursor: pointer;
	display: inline-block;
}

.u-basic-page .u-tabsA .ant-tabs-tabpane {
	padding: 0;
}

.ant-form .u-td-input td {
	padding: 0;
}

.ant-form .u-td-input .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-form .u-td-input .ant-input,
.ant-form .u-td-input .ant-picker {
	border-radius: 0;
	border: none;
	outline: none;
}
.ant-input:focus {
	box-shadow: none;
}
.ant-form .u-td-input .ant-col-15{
	width: 100%;
	max-width: 100%;
}

.u-list-device-form{
	padding: 0 40px 0 0;
}

.u-tip{
	border: 1px solid #ff4d4f;
}

.u-IsTip{
	background: #fafafa;
}

.u-form-Sbtn,
.u-form-Cbtn{
	width: 33px;
	height: 33px;
	margin-right: 10px;
	line-height: 33px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 20px;
	opacity: 0.5;
	text-shadow: none;
	box-shadow: none;
}
.u-form-Sbtn:hover,
.u-form-Cbtn:hover{
	opacity: 1;
}

.u-IsTip{
	background: #fafafa;
}
