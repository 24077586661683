
.e-setting {
  float: left;
  position: absolute;
  right: 0;
}
.ant-form-item.sku-tankuang {
  float: left;
  width: 100%;
}
.ant-radio-button.ant-radio-button-checked {
  background: cornflowerblue;
}
.sku-standard {
  width: 72%;
  float: left;
  left: 9.2%;
}
.sku-attachment {
  width: 28%;
  float: left;
  left: 9.2%;
}
.sku-model2 {
  float: right;
  z-index: 99;
}
.sku-model1 {
  float: left;
  font-size: 18px;
  font-weight: bold;
}
.ant-modal.add-component > .ant-modal-content > .ant-modal-footer {
  text-align: right;
}
.ant-form-item.u-unitId{
  left: 8.3%;
}
.ant-form-item.changdu{
  display: flex;
  align-items: center;
  left: 11.5%;
}
.ant-form-item.kuandu{
  display: flex;
  align-items: center;
  left: -4.5%;
}
.ant-form-item.kezhong{
  display: flex;
  align-items: center;
  left: -20%;
}
.u-FourCon .u-form-l .ant-form-item {
  width: 100%;
}
.u-FourCon .ant-form-item {
  float: left;
  width: 28%;
}
.u-FourCon:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  overflow: hidden;
}
.u-FourCon {
  padding: 10px 0 0 0;
}
.ant-form-item.isProcessingMaterial{
  display: flex;
  align-items: center;
  width: 50%;
  float: left;
  left: -8.5%;
}
.ant-form-item.isMulticolor{
  display: flex;
  align-items: center;
  float: left;
  width: 50%;
  left: -34.5%;
}
.ant-form-item.isProcessingMaterial.ant-form-item-hidden{
  display: flex;
  visibility: hidden;
}
.ant-form-item.isMulticolor.ant-form-item-hidden{
  display: flex;
  visibility: hidden;
}
.deleteA {
  color: #1890ff;
}
.deleteA:hover {
  color: #333;
}
.deleteB {
  color: #1890ff;
  left: 40%;
  position: absolute;
}
.deleteB:hover {
  color: #333;
  left: 40%;
  position: absolute;
}
.u-box-head-factory{
  padding: 10px 0 15px 20px;
}
.u-box-head-factory .u-box-title {
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 0;
}
.sku-Tree-l {
  width: 25%;
  float: left;
}

.ant-form-item.type-goods-form>.ant-col.ant-col-8.ant-form-item-label >label{
  font-size: 25px;
}
.ant-tag.sku-types-span{
  font-size: 18px;
  padding-top: 1.5%;
  padding-bottom: 2%;
}
.ant-form-item.u-form-brand-size{
  width:160%;
}
.ant-input-number.ant-input-number-readonly.component-inputnum{
  display: flex;
  align-items: center;
  right: -109%;
}
