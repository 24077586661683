.tax-add{
    position: absolute;
    left: 44%;
}
.tax-edit{
    position: absolute;
    left: 47%;
}
.tax-delete{
    position: absolute;
    left: 50%;
}