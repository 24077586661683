.form-item{
    width:30%;
    margin-left: 65%;
}
.color-select{
    width: 20%;
}
.color-select-popover{
    margin-left: 0px;
}
.color-select-cover{
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.form-content{
    width: 100%;
    display: flex;
}
.form-l{
    width: 60%;
}
.form-r{
    width: 40%;
    flex: 1;
}
.form-item-in{
    width:14%;
    margin-left:75%;
    borderRight:0px;
    border-bottom-right-radius:0px;
    border-top-right-radius:0px;
}
.form-item-color{
    width:5%;
    border-left:0px;
    border-bottom-left-radius:0px;
    border-top-left-radius:0px;
}