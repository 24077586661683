/*增加关闭按钮*/

.u-drawer .ant-drawer-header-no-title .ant-drawer-close:after {
	content: '';
	width: 18px;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	background: linear-gradient(to right, #f4f4f4, #fff);
}

.u-drawer .ant-drawer-header-no-title .ant-drawer-close::before {
	content: '';
	width: 18px;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	/*background: url(/images/arrows.png) no-repeat center;*/
}


.u-drawer .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
	background: linear-gradient(90deg, rgba(236, 236, 236, 0) 0%, rgba(216, 216, 216, 1) 100%);
}

.u-drawer .ant-drawer-content-wrapper {
	padding-left: 18px;
	background: #fff;
}

.u-drawer .ant-drawer-content {
	overflow-x: hidden;
}

.ant-btn-dangerous.ant-btn-primary {
	background: #BF3232;
	;
	border-color: #BF3232;
}

.u-drawer .ant-drawer-close {
	padding: 10px 20px;
}

/*ant表格重置*/
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
	padding:6px 12px;
}
.ant-table-thead > tr > th{
	padding: 10px;
}
.ant-table-thead>tr>th {
	font-weight: bold;
	color: #333;
}


.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
	border-right: 1px solid #E5E5E5;
}

.ant-table-thead>tr>th:last-child,
.ant-table-tbody>tr>td:last-child,
.ant-table tfoot>tr>th:last-child,
.ant-table tfoot>tr>td:last-child {
	border-right: none;
}

.ant-table-column-sort .ant-table-column-sorters>span {
	color: #BF3232;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
	color: #2C2C2C;
}

.ant-table-column-sorter,
.ant-table-filter-trigger-container {
	/* display: none; */
}

.ant-table-column-sort .ant-table-column-sorter,
.ant-table-column-sort .ant-table-filter-trigger-container,
.ant-table-column-has-sorters:hover .ant-table-column-sorter,
.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
	display: inline-block;
}

.ant-table-filter-trigger {
	/*height: 100%;*/
}

/*ant分页重置*/

.ant-pagination {
	margin-top: 15px;
}

.ant-pagination-item {
	border: none;
}

.ant-pagination-item {
	min-width: 24px;
	height: 24px;
	line-height: 24px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	min-width: 24px;
	height: 24px;
	line-height: 22px;
}

.ant-pagination-item-active {
	background: #E8E8E8;
}

.ant-pagination-item-active a {
	color: #333;
}

.ant-pagination-options-quick-jumper input {
	padding: 0 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #BF3232;
	border-color: #BF3232;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
	border-color: #BF3232;
}


/* 表单 */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-picker {
	border-radius: 4px;
}

.ant-form-item {
	margin-bottom: 8px;
}

.ant-form-item-control-input {
	min-height: 32px;
}

.ant-input {
	padding: 2px 7px;
}

.ant-form-small .ant-form-item-control-input {
	min-height: 28px;
}

.ant-form-item-label>label::after {
	content: ':';
}
.ant-form-item-label > label{
	padding-right: 5px;
}

.ant-input-sm {
	padding: 2px 7px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 28px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 28px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 26px;
}

.ant-input-affix-wrapper {
	padding: 2px 7px;
}

.ant-picker {
	padding-top: 2px;
	padding-bottom: 2px;
}

.ant-form-item-explain {
	display: none;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #EFEFEF;
}

